// eslint-disable-next-line import/prefer-default-export
export const INPUT_SIZES = {
    large: {
        labelSize: '16px',
        labelMarginBottom: '12px',
        lineHeight: '24px',
        valueSize: '16px',
        inputPadding: '12px 4px',
    },
    medium: {
        labelSize: '14px',
        labelMarginBottom: '10px',
        lineHeight: '20px',
        valueSize: '14px',
        inputPadding: '10px 4px',
    },
    small: {
        labelSize: '14px',
        labelMarginBottom: '10px',
        lineHeight: '20px',
        valueSize: '14px',
        inputPadding: '6px 4px',
    },
};
