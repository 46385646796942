import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { FormControl, FormControlLabel as FormControlLabelComponent, FormLabel as FormLabelComponent, Radio, RadioGroup as MuiRadioGroupComponent, } from '@mui/material';
import { ReactComponent as RadioIcon } from '@icons/custom/radio.svg';
import { ReactComponent as RadioCheckedIcon } from '@icons/custom/radio-checked.svg';
const FormLabel = styled(FormLabelComponent)((props) => ({
    color: `${props.theme.palette.text.primary}`,
    fontWeight: 500,
    marginBottom: `${props.theme.spacing_sizes.xs * 1.25}px`,
}));
const FormControlLabel = styled(FormControlLabelComponent)(props => ({
    '& span.MuiFormControlLabel-label.Mui-disabled': {
        color: props.disabled ? `${props.theme.customColors.checkbox.textDisabled}` : '',
    },
}));
const RadioGroupComponent = styled(MuiRadioGroupComponent)(props => ({
    '&.MuiFormGroup-root': {
        gap: `${props.theme.spacing_sizes.xs}px 0`,
    },
    '.MuiFormControlLabel-root': {
        margin: 0,
        span: {
            userSelect: 'none',
        },
    },
    '& span.MuiRadio-root': {
        padding: `0 ${props.theme.spacing_sizes.s * 2}px 0 0`,
    },
}));
const RadioGroup = ({ name = 'radio-group', label = undefined, options = [], value = undefined, defaultValue = undefined, onChange = undefined, disabled = undefined, }) => (_jsxs(FormControl, { children: [label && (_jsx(FormLabel, { children: label })), _jsx(RadioGroupComponent, { name: name, onChange: onChange, value: value, defaultValue: defaultValue, children: options.map((option) => (_jsx(FormControlLabel, { value: option.value, control: (_jsx(Radio, { icon: _jsx(RadioIcon, {}), checkedIcon: _jsx(RadioCheckedIcon, {}) })), label: option.label, disabled: disabled }, option.value))) })] }));
export default RadioGroup;
